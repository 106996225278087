/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 ./public/A1-A4/A4.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/Addons.js';

export default function A4(props) {
    const gltf = useLoader(GLTFLoader, '/frames/A4.glb');
  return (
    <primitive object={gltf.scene}/>
  )
}


useGLTF.preload('/frames/A4.glb')
