export default  function handleYAxisG(frame) {
    switch (frame) {
        case 1:
            return 0.065
        case 2:
            return 0.068
        case 3:
            return 0.010
        default:
            break;
    }
}