import Header from "../../../components/Header/Header";
import HeaderImg from '../../../assets/img/IMG_1385-1.webp'
import LoupeRange from "../../../components/loupeRange/loupeRange";
export default function MagnificationsPage() {
    return (
        <>
            <Header headerImg={HeaderImg}>
            <div>
            <h1 class="header__headline col--xl-7">
                Xpedent<br/>Magnifications
            </h1>


                <div class="header__text col--md-7 col--lg-6">
                    <p>Xpedent offers you a wide range of magnifications available in five loupe systems: Galilean Essential, Galilean HD, Kepler Kompakt, Kepler Reflekt and Kepler Advanced.</p>
                </div>


        </div>
            </Header>
            <section id="module2" class="section ">
                    <div class="grid-wrap grid-wrap--small">
                        <header class="section__header">
                            <h2>
                            Magnifications – Elevating Precision in Dental Practice

                                </h2>
                        </header>
                        <div class="rich-text">
                            <p>
                            At Xpedent, we believe that the cornerstone of exceptional dentistry lies in precision. Our range of magnification solutions is meticulously designed to enhance clarity and improve posture, allowing dental professionals to work with unparalleled accuracy and comfort. Crafted using cutting-edge technology and premium materials, our loupes cater to a variety of needs and preferences.

                            </p>
                            <p>

                            1.⁠ ⁠Galilean Loupes – Where Simplicity Meets Performance
                            Perfect for dentists who demand functionality without compromise, our Galilean loupes deliver outstanding optical clarity and a wide field of view. Designed with lightweight materials and ergonomic frames, they are ideal for prolonged use, ensuring minimal strain on your eyes and neck. Whether you’re performing routine procedures or intricate restorations, Galilean loupes offer reliable magnification and effortless focus.
                            </p>
                            <p>
                            2.⁠ ⁠Ergo Loupes – Redefining Ergonomics
                            Our Ergo loupes are the pinnacle of ergonomic innovation. Featuring a customised tilt angle, they promote optimal posture, helping to reduce fatigue and the risk of musculoskeletal issues. Built with premium Schott Glass and an Achromatic Optical Path Design, these loupes deliver crystal-clear visuals and true-to-life colour representation. Experience precision and comfort like never before with the Ergo range, designed for the modern dental professional.

                            </p>
                        
                            <p>
                            3.⁠ ⁠Slim Scope Loupes – Compact Design, Uncompromised Quality

                            Engineered for those who prioritise lightweight design, our Slim Scope loupes combine minimalistic aesthetics with superior optical performance. These ultra-lightweight loupes are perfect for extended procedures, offering enhanced comfort without sacrificing clarity. Compact yet powerful, Slim Scope loupes are an excellent choice for dental professionals seeking a sleek, modern solution.

                            </p>





                        </div>
                    </div>
            </section>
            <section id="module3" class="section ">
                <div class="grid-wrap grid-wrap--small">
                    <header class="section__header">
                        <h2>Why Choose Xpedent Loupes?</h2>
                    </header>
                    <div class="rich-text">
                        <ul>
                            <li>Exceptional Optics: Achromatic lenses ensure sharp, distortion-free images.
                            </li>
                            <li>Customisable Options: Tailored to fit your specific working distance and preferences.
                            </li>
                            <li>Durable Frames: Crafted from ultra-light alloys for durability and comfort.
                            </li>
                            <li>Enhanced Precision: Improve diagnosis and procedural accuracy with superior magnification.
                            </li>
                        </ul>
        
        <p>Elevate your practice with Xpedent’s magnification solutions and see the difference precision makes. Explore our range of loupes today and discover the perfect tool to meet your needs.

For enquiries or to request a demonstration, please contact us via our website or your local Xpedent representative.</p>
                     </div>

    </div>
            </section>
            {/* <section id="module4" class="section section--grey-light">
                <div class="grid-wrap grid-wrap--small">
                    <header class="section__header">
                        <h2>Galilean Lens System</h2>
                    </header>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    2.3x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                An exceptionally comfortable magnification that is very easy to adjust to. Delivers unequalled freedom of movement, up to 28 cm whilst maintaining a full overview of your working area. Provides an excellent level of detail for common procedures. Recommended for professionals starting to use magnification in their work. The 2.3x is recommended for everyday use to maintain a healthy working posture.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe System</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/nloodzka/magnification-page-galilean-2-3-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>12 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 28 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all day wear</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Easy to adapt to</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes with a large range of movement</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    2.5x Galilean Essential 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                Our entry-level Galilean Essential loupes offer excellent magnification at an affordable price. With an excellent field of view which provides a full overview of your working area, these loupes are a great starter for all day diagnostics.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-essential/">Galilean Essential</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/gpohgadp/magnification-page-essential-2-5-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>11 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 25 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all day wear</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Easy to adapt to</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to a large range of movement</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    2.8x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                Higher magnification for enhanced detail, yet still easy to adjust to and wear all day. Exceptional clarity and depth of field. Available in our HD TTL version, the 2.8x magnification is a well-balanced loupe that will help you to maintain a healthy working posture.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/nusdlr0o/magnification-page-galilean-2-8-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>10.5 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 20 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all general procedures including some special treatments</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Easy to adapt to</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes with a large range of movement</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    3.0x Galilean Essential 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                This entry-level loupe is perfect for all-day diagnostics as well as some specialised treatments, giving you a good balance between field of view and higher magnification. Our Galilean Essential range offers excellent magnification at an affordable price.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-essential/">Galilean Essential</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/bk5f25br/magnification-page-essential-3-0.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>8.3 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 15 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all general procedures including some special treatments</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    3.3x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                Ideal for demanding diagnostics and some special treatments, this lightweight loupe will still provide you with a good working overview as well as a good range of movement. You will find that extra illumination is beneficial at this magnification. We recommend the use of the Total LED light, which is fully portable.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/ztdpmyki/magnification-page-galilean-3-3-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>8 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 15 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for more demanding diagnostics</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Increased magnification whilst maintaining a good field of view</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    3.8x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                The 3.8x magnification gives you an outstanding level of detail whilst maintaining a lovely lightweight loupe. This loupe is designed for anyone who needs a high magnification but where the Kepler system becomes too demanding for their needs. You will find that extra illumination is necessary at this magnification. We recommend the use of the Total Pure &amp; Total Intense.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/wx0mgxfq/magnification-page-galilean-3-8-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>6.5 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 10 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Very high magnification, yet a lightweight, short barrel</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for high-precision work</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
            </section> */}
            <LoupeRange/>
        </>
    )
}